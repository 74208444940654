.leaderboard{
  padding: 10px;
  max-width: 1024px;
  height: 500px;
  margin: 50px auto 0 auto;
  border: 1px solid black;
  overflow: auto;


  .users-container{
    display: flex;
    flex-direction: column;
  }
}
