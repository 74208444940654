.user-item{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0 70px;
  padding: 10px;
  position: relative;

  &.active{
    color: #a100ff;
  }

  &:hover{
    background: rgba(75, 75, 75, 0.06);
    cursor: pointer;
  }

  span{
    font-weight: bold;
    font-size: 20px;
  }

  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;

    svg{
      width: 100%;
      height: 100%;
    }
  }

  .user-details{

    .name{
      font-weight: bold;
    }

    .statistic{
      display: flex;
      gap: 5px;


      .speed{
        color: #a100ff;

        @media screen and (max-width: 340px){
          font-size: 12px;
        }
      }

      .time{
        color: #0909c0;

        @media screen and (max-width: 340px){
          font-size: 12px;
        }
      }
    }

  }
}

#user-span {
  color: black;
  transition: color 0.3s ease;
  position: absolute;
  left: -55px;
}

#user-span:hover {
  color: purple;
}

.avatar {
  position: relative;
}

.avatar::before {
  content: "";
  position: absolute;
  top: -3.5px;
  left: -4.5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid purple;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.user-item.active .avatar::before {
  opacity: 1;
}
